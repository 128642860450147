<template>
  <div class="page">
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          v-for="(t, i) in listData"
          :key="i"
          @click="toPath('publishGoodThingsAdd', true, t.goodsId)"
        >
          <div class="left">
            <img :src="t.goodsPhoto.split(',')[0]" alt="" />
          </div>
          <div class="right">
            <img
              @click.stop="remove(t.goodsId)"
              src="./img/del-icon.png"
              alt=""
              class="delbtn"
            />
            <div class="name" style="margin-bottom: 0">
              {{ t.goodsName }}
            </div>
            <div class="name">
              {{ t.goodsContent }}
            </div>
            <div class="parice"><span>¥</span>{{ t.goodsPrice }}</div>
          </div>
        </div>
      </v-list>
    </div>
    <v-dialog
      confirmButtonColor="#fed763"
      v-model="isDialog"
      title="提示"
      @confirm="confirm"
    >
      <p>是否确认删除该物品？</p>
    </v-dialog>
    <div class="btn" @click="toPath('publishGoodThingsAdd')">发布好物</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { myIssueGoodsListUrl, goodThingsDeleteUrl } from "./api";
import { handleImg } from "@/utils/utils.js";
export default {
  name: "publishGoodThingsList",
  data() {
    return {
      isDialog: false,
      nowId: null,
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  mounted() {},
  methods: {
    confirm() {
      let params = {
        goodsId: this.nowId,
      };
      this.$axios
        .get(`${goodThingsDeleteUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.nowId = null;
            this.isDialog = false;
            this.requestData.curPage = 1;
            this.finished = false;
            this.listData = [];
            this.onLoad();
          }
        });
    },
    remove(id) {
      this.nowId = id;
      this.isDialog = true;
    },
    toPath(name, flag, id) {
      if (flag) {
        this.$router.push({
          name: name,
          query: {
            goodsId: id,
          },
        });
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
      };
      this.$axios
        .get(`${myIssueGoodsListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.goodsPhoto) {
                item.goodsPhoto = handleImg(332, 332, item.goodsPhoto);
              }
              this.listData.push(item);
            });
          } else {
            this.finished = true;
          }
        });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px 30px 140px 30px;
  background: #f9f9f9;
  p {
    text-align: center;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.5);
    padding: 20px 0;
  }
  .list {
    .item {
      width: 100%;
      height: 256px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      margin-bottom: 20px;
      overflow: hidden;
      display: flex;
      .right {
        flex: 1;
        box-sizing: border-box;
        padding: 38px 30px 0;
        position: relative;
        .delbtn {
          position: absolute;
          bottom: 38px;
          right: 28px;
          width: 48px;
        }
        .parice {
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #e02020;
          span {
            font-size: 20px;
          }
        }
        .name {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
          margin-bottom: 30px;
        }
      }
      .left {
        width: 256px;
        height: 256px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 114px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>